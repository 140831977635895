// Override default variables before the import
//$body-bg: #000;
// Theme builder : https://themestr.app/theme
/*! `Monotony` Bootstrap 4 theme */

@import url(https://fonts.googleapis.com/css?family=Montserrat:200,300,400,700);
$font-family-base: Montserrat;
$headings-font-family: Montserrat;

@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital@0;1&display=swap);
$font-family-monospace: Source Code Pro;

//@import url(https://fonts.googleapis.com/css?family=Open+Sans:200,300,400,700);
//$headings-font-family:Open Sans;

$primary: #222222;
$secondary: #666666;
$success: #333333;
$danger: #434343;
$info: #515151;
$warning: #5f5f5f;
$light: #eceeec;
$dark: #111111;

$enable-rounded: false;
//$border-radius:0rem;
//$border-radius-md:0rem;
//$border-radius-lg:0rem;
//$border-radius-sm:0rem;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
