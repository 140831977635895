body {
  /*background: #282c34;*/
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Takes all height for the webapp */
html,
body,
#root,
#root > div {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* override bootstrap style */
.whitedropdown .dropdown-toggle {
  color: white;
}

@counter-style thumbs {
  system: cyclic;
  symbols: '✔️';
  suffix: ' ';
}
